import React, { useState } from 'react'

import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Navigation, EffectCreative, Pagination, Autoplay } from 'swiper'
import 'swiper/css'
import 'swiper/css/pagination'
import 'swiper/css/autoplay'

import slideA from 'assets/mink/carousel/slide-1.mp4'
import slideB from 'assets/mink/carousel/slide-2.png'
import slideC from 'assets/mink/carousel/slide-3.mp4'
import slideD from 'assets/mink/carousel/slide-4.png'
import slideE from 'assets/mink/carousel/slide-5.png'
import slideF from 'assets/mink/carousel/slide-6.png'
import slideG from 'assets/mink/carousel/slide-7.mp4'
import SectionHeading from 'molecules/sectionHeading'
import { ArrowButton } from 'organisms/navigationButtons'

export default function InAction() {
  return (
    <section className="blur-gradient relative overflow-hidden">
      <section className="bg-black grid-lines blade-bottom-padding-lg blade-top-padding-lg relative">
        <SectionHeading title="Our EVs in Action" gradient />
        <div className="blade-top-padding-sm">
          <Carousel />
        </div>
      </section>
    </section>
  )
}

function Slide({
  cover,
  desc,
  title,
}: {
  cover: string
  desc?: string
  title: string
}) {
  return (
    <section className="h-full">
      {/* <div className='p2 rounded-2xl border-[#85DB18] border'> */}
      <div className="h-full px3">
        {title === 'image' ? (
          <div className="w-full h-full bg-black bg-opacity-50 flex items-center justify-center p-3 xl:p-5">
            <img
              src={cover}
              className="w-full md:w-[28rem] lg:w-[30rem] 2xl:w-[36rem] h-[20rem] md:h-[28rem] lg:h-[30rem] 2xl:h-[36rem] object-cover object-top rounded-2xl border-[#85DB18] border"
              alt={desc}
            />
          </div>
        ) : (
          <div className="w-full h-full flex items-center p-3 xl:p-5 justify-center relative overflowhidden">
            <video
              autoPlay
              muted
              loop
              playsInline
              className="absolute w-full h-full object-cover z-0 opacity-50"
              src={cover}
            ></video>
            <video
              autoPlay
              muted
              loop
              playsInline
              src={cover}
              className="z-10 h-[20rem] md:h-[28rem] lg:h-[30rem] 2xl:h-[36rem] w-full md:w-[28rem] lg:w-[30rem] 2xl:w-[36rem] object-cover object-center rounded-2xl border-[#85DB18] border"
            />
          </div>
        )}
      </div>
      {/* </div> */}
    </section>
  )
}

function Carousel() {
  return (
    <div className="cursor-grab relative bolt-carousel">
      <Swiper
        modules={[Pagination, Navigation, Autoplay]}
        initialSlide={0}
        slidesPerView={1}
        spaceBetween={30}
        loop
        autoplay={{ delay: 5000 }}
        grabCursor
        centeredSlides
        slideToClickedSlide
        slideActiveClass="slideActiveClass"
        slideNextClass="slideNextClass"
        slidePrevClass="slidePrevClass"
        speed={800}
        breakpoints={{
          480: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          960: {
            slidesPerView: 2,
            spaceBetween: 20,
          },
          1280: {
            slidesPerView: 3,
            spaceBetween: 30,
          },
          1536: {
            slidesPerView: 3,
            spaceBetween: 40,
          },
        }}
        className="relative px-4 md:px-0"
      >
        <SwiperSlide className="rounded-2xl border-[#85DB18] border relative overflow-hidden">
          <Slide
            title="video"
            cover={slideA}
            desc="Video of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <SwiperSlide className="rounded-2xl border-[#85DB18] border p5 bg-[url(assets/mink/carousel/slide-2.png)] bg-cover overflow-hidden">
          <Slide
            title="image"
            cover={slideB}
            desc="Image of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <SwiperSlide className="rounded-2xl border-[#85DB18] border relative overflow-hidden">
          <Slide
            title="video"
            cover={slideC}
            desc="Video of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <SwiperSlide className="rounded-2xl border-[#85DB18] border p5 overflow-hidden bg-[url(assets/mink/carousel/slide-4.png)] bg-cover">
          <Slide
            title="image"
            cover={slideD}
            desc="Image of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <SwiperSlide className="rounded-2xl border-[#85DB18] border p5 overflow-hidden bg-[url(assets/mink/carousel/slide-5.png)] bg-cover">
          <Slide
            title="image"
            cover={slideE}
            desc="Image of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <SwiperSlide className="rounded-2xl border-[#85DB18] border p5 overflow-hidden bg-[url(assets/mink/carousel/slide-6.png)] bg-cover">
          <Slide
            title="image"
            cover={slideF}
            desc="Image of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <SwiperSlide className="rounded-2xl border-[#85DB18] border  relative overflow-hidden">
          <Slide
            title="video"
            cover={slideG}
            desc="Video of Happy customer with XERO bike."
          />
        </SwiperSlide>
        <div className="flex justify-center items-center mt-6 gap-x-6">
          <NavigationButton direction="left" className="flex" />
          <NavigationButton direction="right" className="flex" />
        </div>
      </Swiper>
    </div>
  )
}

function NavigationButton({
  direction,
  className,
}: {
  direction: 'left' | 'right'
  className: string
}) {
  const swiper = useSwiper()
  const [currentSlide, setCurrentSlide] = useState(1)

  const clickHandler = () => {
    if (direction === 'right') {
      swiper.slideNext()
      setCurrentSlide((prev) => prev + 1)
    } else {
      swiper.slidePrev()
      setCurrentSlide((prev) => prev - 1)
    }
  }

  return (
    <div
      className={`z-10 h-fit w-fit md:absolute md:top-0 md:my-auto group md:bottom-0
    ${
      direction === 'right'
        ? ' md:right-[30px] lg:right-[50px] xl:right-[90px] 2xl:right-[8%]'
        : ' md:left-[30px] lg:left-[50px] xl:left-[90px] 2xl:left-[8%]'
    } ${className}`}
    >
      <ArrowButton direction={direction} theme="dark" onClick={clickHandler} />
    </div>
  )
}
