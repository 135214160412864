import React, { useEffect } from 'react'
import SplitType from 'split-type'

import wheelSvg from '../assets/globals/wheel.svg'
import wheelSvgBlack from '../assets/globals/wheel_black.svg'
import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

export default function SectionHeading({
  title,
  subtitle,
  sectionTag,
  gradient = false,
  showWheel = true,
  color = 'light',
}: {
  sectionTag?: string
  title?: string
  gradient: boolean
  color?: 'light' | 'dark'
  subtitle?: string
  showWheel?: boolean
}) {
  useEffect(() => {
    // const tl = gsap.timeline({
    //   scrollTrigger: {
    //     trigger: '.wrapper',
    //     start: 'top 80%',
    //     end: 'bottom 40%',
    //     markers: false,
    //     scrub: 2,
    //   },
    // })
    // tl.fromTo(
    //   text.chars,
    //   { opacity: 0, scale: 0.95 },
    //   {
    //     opacity: 1,
    //     scale: 1,
    //     duration: 1,
    //     stagger: 0.02,
    //   },
    // )
    // tl.fromTo(
    //   textTop.chars,
    //   { opacity: 0, scale: 0.95 },
    //   {
    //     opacity: 1,
    //     scale: 1,
    //     duration: 1,
    //     stagger: 0.01,
    //   },
    //   0.15,
    // )
  })

  const headingClass = gradient
    ? ' gradient-text'
    : color === 'light'
    ? 'text-white'
    : 'text-[#2B2B2B]'

  const sunheadingClass =
    color === 'light' ? 'text-[#C6C6C6]' : 'text-[#4B4B4B]'

  const sectionTagClass = color === 'light' ? 'text-[#D0D0D0]' : 'text-black'

  return (
    <div className="wrapper">
      {showWheel && (
        <div className="w-4/12 mx-auto">
          {color === 'light' ? (
            <div className="grid place-content-center">
              <div
                className={`h-[40px] ${
                  sectionTag ? 'sm:h-[70px]' : 'sm:h-[60px]'
                }`}
              >
                <img
                  className="w-[160px] h-[100px] sm:h-[160px]"
                  src={wheelSvg}
                  alt=""
                />
              </div>
            </div>
          ) : (
            <div className="grid place-content-center">
              <div className="h-[60px]">
                <img
                  className="w-[160px] h-[160px]"
                  src={wheelSvgBlack}
                  alt=""
                />
              </div>
            </div>
          )}
        </div>
      )}
      <div className="">
        {sectionTag && (
          <h4
            className={`font-medium uppercase ${sectionTagClass} text-center`}
          >
            {sectionTag}
          </h4>
        )}
        {title && (
          <h3
            dangerouslySetInnerHTML={{ __html: title }}
            className={`w-11/12 2xl:w-9/12  mx-auto  font-semibold ${headingClass} ${
              sectionTag && 'pt-1'
            } text-center leading-snug `}
          />
        )}

        {subtitle && (
          <h6
            id="text-target-top"
            dangerouslySetInnerHTML={{ __html: subtitle }}
            className={`font-medium pt-1 text-base sm:text-xl xl:text-2xl  ${sunheadingClass} text-center leading-snug`}
          />
        )}
      </div>
    </div>
  )
}
