import SectionHeading from 'molecules/sectionHeading'
import React, { useRef, useEffect } from 'react'

import vehicle_side_view from 'assets/bolt/vehicle_side_view.png'
import dummyIcon from 'assets/bolt/safety/iconA.svg'
import Uncompromising_battery from 'assets/bolt/final_assets/Uncompromising_battery.svg'
import Uncompromising_dust_dirt from 'assets/bolt/final_assets/Uncompromising_dust_dirt.svg'
import Uncompromising_heavyduty from 'assets/bolt/final_assets/Uncompromising_heavyduty.svg'
import Uncompromising_discbrakes from 'assets/bolt/final_assets/Uncompromising_discbrakes.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)
export default function Safety() {
  let safetyWrapper = useRef<any>()

  useEffect(() => {
    let appeartrigger: any = null
    if (window.innerWidth > 768) {
      appeartrigger = ScrollTrigger.create({
        trigger: safetyWrapper.current,
        start: 'top 60%',
        end: '+=400',
        onEnter: () => {
          safetyWrapper.current.classList.add('safety-active')
        },
      })
    }

    return () => appeartrigger?.kill()
  }, [])

  return (
    <section
      ref={safetyWrapper}
      className="blade-top-padding   safety-section-wrapper bg-cover bg-no-repeat"
    >
      <div className="translate-y-4 md:opacity-0 section-heading max-sm:blade-bottom-margin">
        <SectionHeading
          title="Uncompromising Safety"
          gradient={false}
          color="dark"
          showWheel
        />
      </div>
      <div className="grid w-container-lg grid-cols-9">
        <div
          className=" flex-col justify-center lg:flex  hidden  col-start-1 col-end-3 items-center gap-10 lg:gap-12 2xl:gap-24 
          pt-8 md:pt-8 2xl:pt-20 blade-bottom-padding-lg"
        >
          <Card
            index={0}
            icon={Uncompromising_battery}
            title="LFP as standard battery chemistry with a 60V system"
          />
          <Card
            index={1}
            icon={Uncompromising_dust_dirt}
            title="AIS 156 and IP167 battery certified for dust, dirt, and fire safety standards"
          />
        </div>
        <div
          className="lg:col-start-3 col-start-1 col-end-10 md:col-start-2 md:col-end-9 lg:col-end-8 grid place-content-center 
        place-items-center"
        >
          <img
            src={vehicle_side_view}
            alt="side view of the XERO bolt vehicle"
            className="w-11/12 lg:w-10/12 2xl:w-11/12 h-full object-center object-contain"
          />
        </div>
        <div
          className=" col-start-8 col-end-10 lg:flex hidden  flex-col justify-center items-center gap-10 lg:gap-12 2xl:gap-24 
        pt-8 md:pt-8 2xl:pt-20  blade-bottom-padding-lg "
        >
          <Card
            index={2}
            icon={Uncompromising_discbrakes}
            title="Front and rear disc brakes for enhanced braking performance"
          />
          <Card
            index={3}
            icon={Uncompromising_heavyduty}
            title="Heavy-duty parts and aggregates designed for long uninterrupted life"
          />
        </div>
      </div>
      <div className="lg:hidden  w-container  blade-bottom-padding-lg  blade-top-padding  grid  md:grid-cols-2 grid-cols-1 gap-y-8 md:gap-8 ">
        <Card
          index={0}
          icon={Uncompromising_battery}
          title="LFP as standard battery chemistry with a 60V system"
        />
        <Card
          index={1}
          icon={Uncompromising_dust_dirt}
          title="AIS 156 and IP167 battery certified for dust, dirt, and fire safety standards"
        />

        <Card
          index={2}
          icon={Uncompromising_discbrakes}
          title="Front and rear disc brakes for enhanced braking performance"
        />
        <Card
          index={3}
          icon={Uncompromising_heavyduty}
          title="Heavy-duty parts and aggregates designed for long uninterrupted life"
        />
      </div>
    </section>
  )
}

function Card({
  title,
  icon,
  index,
}: {
  index: number
  title: string
  icon: string
}) {
  return (
    <article
      className={` ${
        index < 2 ? '-translate-y-4' : 'translate-y-4'
      } grid gap-3 md:opacity-0`}
    >
      <div className="grid place-content-center place-items-center">
        <img
          src={icon}
          alt={title}
          className="h-full w-full object-contain object-center"
        />
      </div>
      <span className="max-w-[15rem] lg:max-w-none text-base md:text-xl w-11/12 mx-auto text-center leading-normal 2xl:text-[1.375rem] text-black font-medium">
        {title}
      </span>
    </article>
  )
}
