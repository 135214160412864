import React, { useRef, useEffect } from 'react'
import ContactForm from 'organisms/contact_form'
import bgImage from '../../assets/mink/contact-form-02.jpg'

export default function Contactus() {
  const sectionRef = useRef(null)

  const handleIntersection = (entries: any) => {
    entries.forEach((entry: any) => {
      if (!entry.isIntersecting) {
        window.history.replaceState(
          '',
          document.title,
          window.location.pathname,
        )
      }
    })
  }

  useEffect(() => {
    const observer = new IntersectionObserver(handleIntersection, {
      threshold: 0.5,
    })

    if (sectionRef.current) {
      observer.observe(sectionRef.current)
    }

    return () => {
      if (sectionRef.current) {
        observer.unobserve(sectionRef.current)
      }
    }
  }, [])
  return (
    <section ref={sectionRef} id="book-test-ride">
      <section id="book-now">
        <section id="partner-with-us">
          <section id="contact-us" className="bg-black">
            <ContactForm bgImage={bgImage} />
          </section>
        </section>
      </section>
    </section>
  )
}
