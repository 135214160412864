import { MasterBtn } from 'atoms/buttons'

const data = [
  {
    parameter: 'Ground clearance',
    specification: '235 mm',
    id: '1',
  },
  {
    parameter: 'Overall Length and width',
    specification: '2100 mm x 660mm',
    id: '2',
  },
  {
    parameter: 'Loading capacity',
    specification: 'Tested Up to 300 Kgs',
    id: '3',
  },
  // {
  //   parameter: 'Loading area (LxH)',
  //   specification: 'Front 400/370 mm x 290 mm',
  //   id: '4',
  // },
  {
    parameter: 'Loading area (LxW)',
    specification: 'Rear 400 mm x 255 mm',
    id: '5',
  },
]

export default function Specifications() {
  const handleSpecificationDownload = () => {
    console.log('download the specifications')
  }
  return (
    <div className="blade-top-padding-lg blade-bottom-padding-lg bolt-specifications-wrapper">
      <div className="">
        <h3
          className="font-semibold text-center bg-green-gradient text-transparent bg-clip-text
        "
        >
          XERO Bolt Specifications
        </h3>

        <div className="hidden sm:block">
          <table
            border={0}
            className="mt-8 md:mt-8 2xl:mt-12 w-[90%] md:w-[86%] lg:w-[70%] xl:w-[56%] border-none 2xl:w-[42%] mx-auto"
          >
            <tr className="bg-[#363636] border-none text-white">
              <th className="font-medium border-none py-3 2xl:py-5 px-6 md:pl-10 text-left text-lg md:text-2xl">
                Parameter
              </th>
              <th className="font-medium border-none px-6 md:pl-12 text-left text-lg md:text-2xl">
                Specification
              </th>
            </tr>
            {data.map((item) => (
              <tr className="even:bg-[#282828] odd:bg-[#363636]">
                <td
                  className={`font-regular px-6 md:pl-9 text-sm md:text-xl flex md:items-center gap-x-2 md:gap-x-4 text-white py-5 border-none`}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width={19}
                      height={17}
                      viewBox="0 0 19 17"
                      fill="none"
                    >
                      <path
                        d="M9.52734 0.681641L11.548 6.90049H18.0869L12.7968 10.7439L14.8174 16.9628L9.52734 13.1193L4.23728 16.9628L6.2579 10.7439L0.967835 6.90049H7.50672L9.52734 0.681641Z"
                        fill="#828282"
                      />
                      <path
                        d="M9.52734 0.681641L11.548 6.90049H18.0869L12.7968 10.7439L14.8174 16.9628L9.52734 13.1193L4.23728 16.9628L6.2579 10.7439L0.967835 6.90049H7.50672L9.52734 0.681641Z"
                        fill="url(#paint0_linear_2653_1469)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2653_1469"
                          x1="9.52734"
                          y1="-9.18697"
                          x2="9.52734"
                          y2="25.6008"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stopColor="white" />
                          <stop offset="0.861167" stopColor="#85DB18" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </span>
                  {item.parameter}
                </td>
                <td className="font-regular px-6 md:pl-[50px] text-sm md:text-xl text-white py-2 border-none">
                  {item.specification}
                </td>
              </tr>
            ))}
          </table>
        </div>

        {/* Mobile View  */}

        <div className="sm:hidden px-4 mt-8">
          {data.map((item) => (
            <div className="mt-4">
              <p className="font-medium bg-[#363636] px-2 py-1 flex items-center text-opacity-80 text-white text-base">
                <span className="-mt-1 mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width={19}
                    height={17}
                    viewBox="0 0 19 17"
                    fill="none"
                  >
                    <path
                      d="M9.52734 0.681641L11.548 6.90049H18.0869L12.7968 10.7439L14.8174 16.9628L9.52734 13.1193L4.23728 16.9628L6.2579 10.7439L0.967835 6.90049H7.50672L9.52734 0.681641Z"
                      fill="#828282"
                    />
                    <path
                      d="M9.52734 0.681641L11.548 6.90049H18.0869L12.7968 10.7439L14.8174 16.9628L9.52734 13.1193L4.23728 16.9628L6.2579 10.7439L0.967835 6.90049H7.50672L9.52734 0.681641Z"
                      fill="url(#paint0_linear_2653_1469)"
                    />
                    <defs>
                      <linearGradient
                        id="paint0_linear_2653_1469"
                        x1="9.52734"
                        y1="-9.18697"
                        x2="9.52734"
                        y2="25.6008"
                        gradientUnits="userSpaceOnUse"
                      >
                        <stop stopColor="white" />
                        <stop offset="0.861167" stopColor="#85DB18" />
                      </linearGradient>
                    </defs>
                  </svg>
                </span>
                {item.parameter}
              </p>
              <p className="font-medium bg-[#282828] px-7 py-2 text-white text-lg">
                {item.specification}
              </p>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center mt-8 md:mt-12">
          <button
            type="button"
            className={` bg-darkGray px-7 tracking-wide font-medium rounded-md outline-none focus:outline-none active:outline-none transition-all 
      duration-300 ease-in-out text-white border-solid border-white md:text-lg py-3 border-[1.5px] border-opacity-10 hover:border-opacity-60 focus:border-opacity-60 text-base`}
          >
            Download Specifications
          </button>
        </div>
      </div>
    </div>
  )
}
