import React, { useEffect, useRef } from 'react'
import { MasterBtn } from 'atoms/buttons'
import SectionHeading from 'molecules/sectionHeading'
import one from 'assets/home/smartRide/battery.svg'
import two from 'assets/home/smartRide/seat.svg'
import three from 'assets/home/smartRide/tyre.svg'
import four from 'assets/home/smartRide/drive-line.svg'
import five from 'assets/home/smartRide/cost.png'
import sideView from 'assets/home/smartRide/side-view.jpg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { useNavigate } from 'react-router-dom'

gsap.registerPlugin(ScrollTrigger)

const pointcard = [
  {
    title: '80-120 km range with detachable/swappable safe battery options',
    icon: one,
  },
  {
    title:
      'High Loading <br class="hidden md:block" /> space with  modular <br class="hidden md:block" />  rear seat',
    icon: two,
  },
  {
    title:
      'Long-life rugged <br class="hidden md:block" />  aggregates with <br class="hidden md:block" />  large 17-inch tires',
    icon: three,
  },
  {
    title:
      'Intelligent drive line to <br class="hidden md:block" />  adjust performance <br class="hidden md:block" />  with load ',
    icon: four,
  },
  {
    title:
      'Best-in-class <br class="hidden md:block" />  total cost of <br class="hidden md:block" />  ownership',
    icon: five,
  },
]

export default function SmartRide() {
  const nodeRef = useRef<any>()
  const navigate = useNavigate()
  const handleClick = () => {
    navigate('/bolt')
  }

  useEffect(() => {
    // const ctx = gsap.context(() => {
    //   gsap.fromTo(
    //     nodeRef.current,
    //     {
    //       scale: 0.75,
    //     },
    //     {
    //       scale: 1,
    //       scrollTrigger: {
    //         trigger: nodeRef.current,
    //         start: 'top 80%',
    //         scrub: 0,
    //         end: 'bottom 300px',
    //       },
    //     },
    //   )
    // }, nodeRef)
    // return () => ctx.revert()
  }, [])

  return (
    <section className="blur-gradient relative">
      <div className="bg-black grid-lines bg-founders blade-top-padding-lg blade-bottom-padding-lg">
        <SectionHeading sectionTag="Xero Bolt" color="light" gradient />
        <div className="">
          <div className="pt-10 md:pt-12 w-full md:w-[70%] mx-auto px-4">
            <div className="grid rounded-xl place-content-center">
              <img src={sideView} alt="" className="rounded-xl" />
            </div>
          </div>
        </div>
        <div className="pt-10 md:pt-12">
          <h3
            className={`w-11/12 2xl:w-9/12 mx-auto  font-semibold pt-1
          text-center leading-snug gradient-text`}
          >
            Your smart ride with a purpose
          </h3>
          <h6 className="text-[#D0D0D0] text-center leading-snug font-medium pt-1 text-base sm:text-xl xl:text-2xl ">
            Safe. Tough. Profitable
          </h6>

          <div className="flex flex-col md:flex-row flex-wrap justify-center gap-x-5 gap-y-8 px-4 mt-12">
            <div className="flex flex-col md:flex-row items-center justify-center gap-x-5 gap-y-8">
              {pointcard.map((elem, index) => {
                const key = `${index}`
                if (index < 3) {
                  return <FeatureCard key={key} data={elem} />
                }
                return null
              })}
            </div>
            <div className="flex flex-col md:flex-row items-center justify-center gap-x-5 gap-y-8">
              {pointcard.map((elem, index) => {
                const key = `${index}`
                if (index >= 3) {
                  return <FeatureCard key={key} data={elem} />
                }
                return null
              })}
            </div>
          </div>
          <div className="grid place-content-center mt-12">
            <MasterBtn
              text="Know more"
              onClick={handleClick}
              type="button"
              size="base"
              className="px-10"
            />
          </div>
        </div>
      </div>
    </section>
  )
}

type Feature = {
  title: string
  icon: string
}

function FeatureCard({
  data,
  className,
}: {
  data: Feature
  className?: string
}) {
  const { title, icon } = data
  return (
    <article
      className={`flex w-[300px] md:w-[200px] 2xl:w-[250px] flex-col gap-5 ${className}`}
    >
      <div className="grid place-content-center">
        <div className="w-[100px] h-[100px] xl:w-[120px] xl:h-[120px]  2xl:w-full 2xl:h-full ">
          <img className="select-none" src={icon} alt={title} />
        </div>
      </div>
      <div className="w-full px-3 md:max-w-xs  mx-auto">
        <span
          className="text-white xl:pt-2 text-base block text-center font-regular"
          dangerouslySetInnerHTML={{ __html: title }}
        />
      </div>
    </article>
  )
}
