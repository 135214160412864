import SectionHeading from 'molecules/sectionHeading'
import image1 from '../../assets/mink/accessories/helmet.png'
import image2 from '../../assets/mink/accessories/front-carrier.png'
import image3 from '../../assets/mink/accessories/side-bag.png'
import image4 from '../../assets/mink/accessories/rear view-mirrors.png'
import image5 from '../../assets/mink/accessories/mobile-holding-stand.png'
import image6 from '../../assets/mink/accessories/helmet-lock.png'
import image7 from '../../assets/mink/accessories/child-seat.png'
import { Swiper, SwiperSlide, useSwiper } from 'swiper/react'
import { Autoplay } from 'swiper'
import './index.css'
import 'swiper/css'
import 'swiper/css/pagination'

const data = [
  { id: 1, url: image1, name: 'Helmet' },
  { id: 2, url: image2, name: 'Front carrier' },
  { id: 3, url: image3, name: 'Side bag' },
  { id: 4, url: image4, name: 'Rear view mirrors' },
  { id: 5, url: image5, name: 'Mobile holding stand' },
  { id: 6, url: image6, name: 'Helmet lock' },
  { id: 7, url: image7, name: 'Child seat' },
]

const Card = ({ url, name }: { url: string; name: string }) => {
  return (
    <div
      className="accessories-card h-[320px] sm:h-[350px] md:h-[320px] 2xl:h-[480px] 
    flex flex-col relative items-center justify-center rounded-lg"
    >
      <img
        className="h-[240px] md:h-[220px] 2xl:h-[400px]"
        src={url}
        alt={name}
      />
      <p className="font-medium text-2xl bottom-6">{name}</p>
    </div>
  )
}

const NavigationButtons = () => {
  const swiper = useSwiper()
  return (
    <div className="flex items-center justify-center mt-8 gap-x-6 md:gap-x-12">
      <div
        className={`arrow-btn-gradient cursor-pointer group
    w-[50px] h-[50px] lg:w-[65px] lg:h-[65px] xl:w-[70px] xl:h-[70px] rounded-full p-[2px]`}
      >
        <span
          onClick={() => {
            swiper.slidePrev()
          }}
          className=" w-full h-full relative bg-black rounded-full hover:bg-white flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="inherit"
            className="w-6 h-6 lg:w-8 lg:h-8 stroke-[2.8px] stroke-white group-hover:stroke-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M15.75 19.5L8.25 12l7.5-7.5"
            />
          </svg>
        </span>
      </div>
      <div
        className={`arrow-btn-gradient cursor-pointer group
    w-[50px] h-[50px] lg:w-[65px] lg:h-[65px] xl:w-[70px] xl:h-[70px] rounded-full p-[2px]`}
      >
        <span
          onClick={() => {
            swiper.slideNext()
          }}
          className="w-full h-full relative bg-black rounded-full hover:bg-white flex items-center justify-center"
        >
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={1.5}
            stroke="inherit"
            className="w-6 h-6 lg:w-8 lg:h-8 stroke-[2.8px] stroke-white group-hover:stroke-black"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M8.25 4.5l7.5 7.5-7.5 7.5"
            />
          </svg>
        </span>
      </div>
    </div>
  )
}

export const Accessories = () => {
  return (
    <div className="blur-gradient relative overflow-hidden">
      <div className="blade-top-padding-lg blade-bottom-padding-lg bg-black">
        <SectionHeading gradient title="XERO Mink Accessories" />
        <Swiper
          className="mt-8 cursor-grab px-4 md:px-12 md:mt-12 relative"
          loop
          autoplay={{ delay: 3000 }}
          grabCursor
          modules={[Autoplay]}
          breakpoints={{
            280: {
              slidesPerView: 1,
              spaceBetween: 30,
            },
            640: {
              slidesPerView: 1.5,
              spaceBetween: 30,
            },
            768: {
              slidesPerView: 2,
              spaceBetween: 30,
            },
            1024: {
              slidesPerView: 2.3,
              spaceBetween: 30,
            },
            1280: {
              slidesPerView: 3,
              spaceBetween: 30,
            },
            1536: {
              slidesPerView: 3.4,
              spaceBetween: 30,
            },
          }}
        >
          {data.map((item) => {
            return (
              <SwiperSlide>
                <Card name={item.name} url={item.url} />
              </SwiperSlide>
            )
          })}
          <NavigationButtons />
        </Swiper>
      </div>
    </div>
  )
}
