import React, { useState, useRef, useLayoutEffect } from 'react'
import gsap from 'gsap'

export default function FAQ({
  accordionData,
  bgClass,
}: {
  bgClass: string
  accordionData: AccordionItem[]
}) {
  const [showMore, setShowMore] = useState(false)
  const [openIndex, setOpenIndex] = useState<number | null>(null)

  const handleOpen = (index: number) => {
    setOpenIndex((prevIndex) => (prevIndex === index ? null : index))
  }

  return (
    <div className={` ${bgClass} blade-top-padding-lg blade-bottom-padding-lg`}>
      <div className="text-center px-3">
        <h3 className="text-[#2B2B2B] font-semibold tracking-wide">
          Frequently Asked Questions
        </h3>
      </div>

      <div className="mt-8 lg:mt-16 px-3">
        <div className=" flex flex-col gap-5 md:gap-6 lg:gap-7 max-w-4xl md:w-11/12 mx-auto">
          {accordionData.map((accordion: AccordionItem, index: number) => {
            const key = index
            if (showMore) {
              return (
                <Accordion
                  key={key}
                  title={accordion.title}
                  content={accordion.content}
                  isOpen={openIndex === key}
                  index={key}
                  handleOpen={handleOpen}
                />
              )
            } else if (index <= 3) {
              return (
                <Accordion
                  key={key}
                  title={accordion.title}
                  content={accordion.content}
                  isOpen={openIndex === key}
                  index={key}
                  handleOpen={handleOpen}
                />
              )
            }
            return null
          })}
          <div className="flex justify-end">
            <button
              onClick={() => setShowMore(!showMore)}
              className="border-none cursor-pointer bg-transparent text-base md:text-xl font-medium"
            >
              {`${showMore ? 'Read Less' : 'Read More...'}`}
            </button>
          </div>
        </div>
      </div>
    </div>
  )
}

interface AccordionProps {
  title: string
  content: string
  isOpen: boolean
  index: number
  handleOpen: (index: number) => void
}

export interface AccordionItem {
  title: string
  content: string
}

function Accordion({
  title,
  content,
  handleOpen,
  index,
  isOpen,
}: AccordionProps) {
  const accordionRef = useRef<HTMLDivElement>(null)
  const toggleAccordion = () => {
    handleOpen(index)
  }

  useLayoutEffect(() => {
    const accordionContent = accordionRef.current as HTMLElement
    if (isOpen) {
      gsap.to(accordionContent, { height: 'auto', duration: 0.3, padding: 0 })
    } else {
      gsap.to(accordionContent, { height: 0, duration: 0.3, padding: 0 })
    }
  }, [isOpen])

  return (
    <div
      className={` ${
        isOpen ? '' : ''
      }  bg-white px-2 py-6 self-start accordion-item border-opacity-30 rounded-3xl overflow-hidden`}
    >
      <button
        type="button"
        className="accordion-header flex transition-colors duration-300 ease-in-out items-center gap-3  
        group outline-none rounded-md  pl-1 pr-2 md:px-5 w-full"
        onClick={toggleAccordion}
      >
        <h6 className="flex-1 font-medium leading-tight px-2 text-left">
          {index + 1}.<span className="pl-[4px]">{title}</span>
        </h6>
        <div className="bg-black grid place-content-center p-2 lg:p-3 rounded-full">
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={3}
            stroke="white"
            className={`  md:w-5  h-4 md:h-5 ${
              isOpen ? 'rotate-180' : ''
            } duration-300 ease-in-out transition-all`}
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M19.5 8.25l-7.5 7.5-7.5-7.5"
            />
          </svg>
        </div>
      </button>
      <div
        className={`accordion-content overflow-hidden ${isOpen ? '' : 'h-0'}`}
        ref={accordionRef}
      >
        <span
          className="font-medium opacity-70 mt-2 md:mt-0 block leading-tight md:leading-normal text-sm md:text-base 2xl:text-lg 
        px-3 md:px-7 w-[86%] pb-3"
        >
          {content}
        </span>
      </div>
    </div>
  )
}
