import React, { useEffect, useLayoutEffect, useRef } from 'react'
import ScreenWrapper from 'layouts/screen'
import Marquee from 'molecules/marquee'
import Banner from './01_banner'
import About from './02_about'
import Founders from './03_founders'
import Certifications from './04_certifications'
import Smartride from './smartRide'
import Partners from './partners'
import ThroughTraffic from './throughTraffic'
import ContactUs from './05_form'
import Carousal from './carousals/carousel'
import './index.css'
import MobileScreen from './mobileScreen'

export default function HomePage() {
  const aboutUsRef = useRef<HTMLDivElement>(null)
  const contactRef = useRef<HTMLDivElement>(null)

  const urlHash = window.location.hash

  useEffect(() => {
    if (
      urlHash.length &&
      urlHash.substring(1) === 'about-us' &&
      aboutUsRef &&
      aboutUsRef.current
    ) {
      aboutUsRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    } else if (
      urlHash.length &&
      contactRef &&
      contactRef.current &&
      (urlHash.substring(1) === 'contact-us' ||
        urlHash.substring(1) === 'partner-with-us' ||
        urlHash.substring(1) === 'book-now')
    ) {
      contactRef.current.scrollIntoView({ behavior: 'smooth', block: 'end' })
    } else {
      window.scroll({ top: 0, behavior: 'smooth' })
    }
  }, [urlHash])

  return (
    <ScreenWrapper>
      <Banner />
      <div ref={aboutUsRef}>
        <About />
      </div>
      <Smartride />
      <ThroughTraffic />

      <MobileScreen />
      <Founders />
      {/* <Partners /> confirm with aish before commenting out */}
      <Certifications />
      <div ref={contactRef}>
        <ContactUs />
      </div>
      {/* <Marquee text="Stay tuned for more...!" variant="light" /> */}
    </ScreenWrapper>
  )
}
