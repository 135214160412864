import SectionHeading from 'molecules/sectionHeading'
import React, { useRef, useEffect } from 'react'

import limitless_modularseat from 'assets/bolt/final_assets/limitless_modularseat.svg'
import limitless_money from 'assets/bolt/final_assets/limitless_money.svg'
import limitless_motor from 'assets/bolt/final_assets/limitless_motor.svg'
import limitless_digitaldisplay from 'assets/bolt/final_assets/limitless_digitaldisplay.svg'
import limitless_battery from 'assets/bolt/final_assets/limitless_battery.svg'

import bolt_left_full_view from 'assets/bolt/bolt_left_full_view.png'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const pointers = [
  {
    title: 'Unparalleled loading space with modular rear seat',
    icon: limitless_modularseat,
  },
  {
    title: 'Indigenous 1500-watt BLDC motor with smart controller',
    icon: limitless_motor,
  },
  {
    title:
      'CAN integrated digital display panel for precise information and controls',
    icon: limitless_digitaldisplay,
  },
  {
    title: 'Best in class total cost of ownership',
    icon: limitless_money,
  },
  {
    title: '80 - 140 km battery range with 3-4 hours charging time',
    icon: limitless_battery,
  },
]

export default function Potential() {
  let wrapper = useRef<any>()

  useEffect(() => {
    let appeartrigger: any = null
    if (window.innerWidth > 768) {
      appeartrigger = ScrollTrigger.create({
        trigger: wrapper.current,
        start: 'top 50%',
        end: '+=400',
        onEnter: () => {
          wrapper.current.classList.add('potential-active')
        },
      })
    }
    return () => appeartrigger?.kill()
  }, [])
  return (
    <section
      ref={wrapper}
      className="potential-wrapper blade-top-padding-lg blade-bottom-padding-lg"
    >
      <div className="section-heading translate-y-4 md:opacity-0">
        <SectionHeading
          title="Limitless Potential"
          gradient={false}
          color="dark"
          showWheel
        />
      </div>
      <div className="vehicle-view-wrapper bg-[center_top] bg-no-repeat mt-8 md:mt-10 2xl:mt-20 pb-3 md:pb-5">
        <div className=" place-content-center place-items-center grid max-w-[55rem] w-container-sm  vehicle-wrapper ">
          <img
            src={bolt_left_full_view}
            alt="side view of bolt vehicle by xero"
            className=" w-11/12 lg:w-full h-full object-contain object-center translate-y-10 md:opacity-0"
          />
        </div>
      </div>
      <div className="grid potential-pointers-wrapper gap-y-6 md:gap-y-8 lg:gap-y-14 2xl:gap-y-28  w-container-sm pt-8 md:pt-10 2xl:pt-20 pb-10">
        {pointers.map((elem, index: number) => {
          const key = `${index}`
          const { title, icon } = elem
          return (
            <Card
              noBorder={[0, 3].includes(index)}
              key={key}
              title={title}
              icon={icon}
            />
          )
        })}
      </div>
    </section>
  )
}

function Card({
  title,
  icon,
  noBorder,
}: {
  title: string
  icon: string
  noBorder: boolean
}) {
  return (
    <article className="grid  group gap-3 xl:px-8  px-3 md:px-5 relative translate-y-4 md:opacity-0">
      <div
        className={`absolute  md:block md:group-odd:hidden hidden left-0 right-auto top-0 bottom-0 w-px bg-gradient-to-b opacity-60 from-transparent via-dark to-transparent ${
          noBorder ? 'lg:hidden' : 'lg:group-odd:block '
        }`}
      />
      <div className="grid place-content-center place-items-center">
        <img
          src={icon}
          alt={title}
          className="h-full w-full object-contain object-center"
        />
      </div>
      <span className=" max-w-[15rem] lg:max-w-[18rem] text-base md:text-xl  w-10/12 mx-auto text-center leading-normal 2xl:text-[1.375rem] text-black font-medium">
        {title}
      </span>
    </article>
  )
}
