import { useState, useEffect, useRef } from 'react'
import bolt from 'assets/home/bolt.jpg'
import bolt_mobile from 'assets/home/bolt_mobile.jpg'
import { SwitchTransition, CSSTransition } from 'react-transition-group'
import { MasterAnchor } from 'atoms/links'

const specifications = [
  { id: '1', title: ' Range options upto 140 kms' },
  { id: '2', title: 'Loading capacity upto 300 Kgs' },
  { id: '3', title: 'Rugged and Reliable built' },
]

export default function Banner() {
  const [viewIndex, setViewIndex] = useState(0)
  const nodeRef = useRef<any>()
  useEffect(() => {
    const interval = setInterval(() => {
      setViewIndex((prev) => (prev + 1) % specifications.length)
    }, 3000)
    return () => clearInterval(interval)
  }, [])

  return (
    <section
      className="min-h-[600px] md:min-h-[760px] lg:min-h-[560px] mink-banner flex flex-col items-center xl:h-screen 
    bg-cover bg-top bg-no-repeat overflow-hidden relative"
    >
      <div
        className="z-20 w-full mt-[150px] sm:mt-[100px] md:mt-[140px] lg:mt-[120px] xl:mt-[160px] 2xl:mt-[200px]
        sm:max-w-[600px] md:max-w-[650px] flex flex-col items-center md:items-start lg:max-w-[960px] lg:px-4 xl:max-w-[1200px] 
        2xl:max-w-[1480px] mx-auto"
      >
        <h3
          className="font-semibold text-[26px] md:text-[36px] xl:text-[40px] 2xl:text-[48px] leading-tight text-black  
        max-w-[250px] md:max-w-sm text-center md:text-left"
        >
          Your smart ride <br /> with a purpose
        </h3>
        <p className="text-base md:text-xl 2xl:text-2xl mt-2 font-medium text-black">
          Safe. Tough. Profitable
        </p>

        <div className="mt-6">
          <MasterAnchor
            href="#book-test-ride"
            size="large"
            className=" bg-lightGreen px-6 md:px-8 !py-3 md:py-[14px] border-none text-black"
            text="Test ride your Bolt"
          />
        </div>
      </div>

      <div className="absolute md:w-full z-20 bottom-4 md:bottom-6 lg:bottom-8">
        <div className="md:flex px-4 justify-center items-center gap-x-2 md:gap-x-4 xl:gap-x-6 hidden">
          {specifications.map((item) => (
            <>
              <p
                className="font-medium max-w-[240px] md:max-w-[300px] leading-5 text-center lg:w-full text-white opacity-80 
              text-[14px] lg:text-lg"
              >
                {item.title}
              </p>
              <div className="w-[2px] bg-white opacity-60 h-[42px] last-of-type:hidden" />
            </>
          ))}
        </div>
        <div className="relative md:hidden">
          <SwitchTransition>
            <CSSTransition
              key={viewIndex}
              nodeRef={nodeRef}
              timeout={1000}
              classNames="mink-banner-text"
              unmountOnExit
            >
              <ul className="list-none inline " ref={nodeRef}>
                <p className="font-medium max-w-[290px] text-center text-white text-[14px]">
                  {specifications[viewIndex].title}
                </p>
              </ul>
            </CSSTransition>
          </SwitchTransition>
        </div>
      </div>

      <div className="absolute top-0 2xl:-bottom-28 left-auto h-full w-full">
        <img
          className="hidden lg:block w-full h-full xl:h-[108%] 2xl:h-full object-cover  lg:object-right-[20%] xl:right-10 2xl:object-right"
          src={bolt}
          alt=""
        />
        <img
          src={bolt_mobile}
          className="lg:hidden w-full h-full object-right-bottom object-cover"
          alt=""
        />
      </div>
    </section>
  )
}
