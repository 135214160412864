import './index.css'
import { MasterBtn } from 'atoms/buttons'

const data = [
  { id: 1, parameter: 'Battery Energy', specification: '1.0kW/2.0kW/3.0kW' },
  { id: 2, parameter: 'Ground clearance', specification: '205 mm' },
  {
    id: 3,
    parameter: 'Overall length and width',
    specification: '1775mm x 675mm',
  },
  { id: 4, parameter: 'Loading capacity', specification: '100 Kgs' },
  {
    id: 5,
    parameter: 'Loading area',
    specification: 'Front (275mm x 245mm)',
    other: 'Rear (290 mm x 270 mm ) ',
  },
]

export const Specifications = () => {
  return (
    <div className="blade-top-padding-lg blade-bottom-padding-lg specifications-wrapper">
      <div className="">
        <h3 className="font-semibold text-center text-[#2B2B2B]">
          XERO Mink Specifications
        </h3>

        <div className="hidden sm:block">
          <table
            border={0}
            className="mt-8 md:mt-8 2xl:mt-12 w-[90%] md:w-[86%] lg:w-[70%] xl:w-[56%] border-none 2xl:w-[42%] mx-auto"
          >
            <tr className="bg-[#CECECE] border-none">
              <th className="font-medium border-none py-2 2xl:py-5 px-6 md:pl-20 text-left text-lg xl:text-2xl">
                Parameter
              </th>
              <th className="font-medium border-none px-6 md:pl-12 text-left text-lg xl:text-2xl">
                Specification
              </th>
            </tr>
            {data.map((item) => (
              <tr
                className={`${
                  item.id % 2 === 0 ? 'bg-[#CECECE]' : 'bg-white'
                } border-none`}
              >
                <td
                  className={`font-medium px-6 md:pl-[74px] text-base md:text-lg 2xl:text-xl flex md:items-center gap-x-2 md:gap-x-4 
                  text-[#333333] py-3 2xl:py-4 border-none`}
                >
                  <span>
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      viewBox="0 0 18 17"
                      fill="none"
                      className="w-4 h-4 md:w-[18px] md:h-[18px] mt-[2px] md:-mt-[2px]"
                    >
                      <path
                        d="M8.97656 0.573242L10.9972 6.79209H17.5361L12.246 10.6355L14.2666 16.8544L8.97656 13.0109L3.6865 16.8544L5.70712 10.6355L0.417054 6.79209H6.95594L8.97656 0.573242Z"
                        fill="#333333"
                      />
                      <path
                        d="M8.97656 0.573242L10.9972 6.79209H17.5361L12.246 10.6355L14.2666 16.8544L8.97656 13.0109L3.6865 16.8544L5.70712 10.6355L0.417054 6.79209H6.95594L8.97656 0.573242Z"
                        fill="#333333"
                      />
                    </svg>
                  </span>
                  {item.parameter}
                </td>
                <td className="font-medium px-6 md:pl-[50px] text-base md:text-xl text-[#333333] py-2 border-none">
                  {item.specification}
                  <span className="block">{item?.other}</span>
                </td>
              </tr>
            ))}
          </table>
        </div>

        {/* Mobile view  */}
        <div className="sm:hidden px-4 mt-8">
          {data.map((item) => (
            <div className="mt-4">
              <p className="font-medium bg-[#CECECE] px-2 py-1 flex items-center text-opacity-80 text-black text-base">
                <span className="-mt-1 mr-1">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    viewBox="0 0 18 17"
                    fill="none"
                    className="w-4 h-4 md:w-[18px] md:h-[18px] mt-[2px] md:-mt-[2px]"
                  >
                    <path
                      d="M8.97656 0.573242L10.9972 6.79209H17.5361L12.246 10.6355L14.2666 16.8544L8.97656 13.0109L3.6865 16.8544L5.70712 10.6355L0.417054 6.79209H6.95594L8.97656 0.573242Z"
                      fill="#333333"
                    />
                    <path
                      d="M8.97656 0.573242L10.9972 6.79209H17.5361L12.246 10.6355L14.2666 16.8544L8.97656 13.0109L3.6865 16.8544L5.70712 10.6355L0.417054 6.79209H6.95594L8.97656 0.573242Z"
                      fill="#333333"
                    />
                  </svg>
                </span>
                {item.parameter}
              </p>
              <p className="font-medium bg-white bg-opacity-80 px-7 py-2 text-black text-lg">
                {item.specification}
                <span className="block">{item?.other}</span>
              </p>
            </div>
          ))}
        </div>

        <div className="flex items-center justify-center mt-8 2xl:mt-12">
          <MasterBtn
            type="button"
            text="Download Specifications"
            size="large"
            className="px-8 py-[10px]"
            theme="light"
          />
        </div>
      </div>
    </div>
  )
}
