import React, { useRef, useEffect } from 'react'
import SectionHeading from 'molecules/sectionHeading'
import heavyDuty from 'assets/bolt/performance/first.png'
import Extraordinary_battery from 'assets/bolt/final_assets/Extraordinary_battery.jpg'
import Extraordinary_multiplemodes from 'assets/bolt/final_assets/Extraordinary_multiplemodes.jpg'
import Extraordinary_heavyduty from 'assets/bolt/final_assets/Extraordinary_heavyduty.jpg'
import Extraordinary_largetires from 'assets/bolt/final_assets/Extraordinary_largetires.jpg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'

gsap.registerPlugin(ScrollTrigger)

const pointers = [
  {
    title: 'Multiple modes and reverse option to optimize performance',
    cover: Extraordinary_multiplemodes,
  },
  {
    title: 'Large 17-inch tires to navigate through tough terrains',
    cover: Extraordinary_largetires,
  },
  {
    title: 'Heavy duty front and rear suspension',
    cover: Extraordinary_heavyduty,
  },
  {
    title: 'Detachable and swappable battery options',
    cover: Extraordinary_battery,
  },
]

export default function Performance() {
  let performanceWrapper = useRef<any>()

  useEffect(() => {
    let appeartrigger: any = null
    if (window.innerWidth > 768) {
      appeartrigger = ScrollTrigger.create({
        trigger: performanceWrapper.current,
        start: 'top 60%',
        end: '+=400',
        onEnter: () => {
          performanceWrapper.current.classList.add('performance-active')
        },
      })
    }

    return () => appeartrigger?.kill()
  }, [])
  return (
    <section
      ref={performanceWrapper}
      className="bg-dark grid-lines blade-top-padding-lg blade-bottom-padding-lg "
    >
      <div className=" blade-bottom-padding section-heading translate-y-4 md:opacity-0">
        <SectionHeading
          title="Extraordinary Performance"
          gradient
          color="light"
          showWheel
        />
      </div>
      <div className="w-container grid grid-cols-1 lg:grid-cols-2  gap-y-10 md:gap-12 lg:gap-14  2xl:gap-16">
        {pointers.map((elem, index: number) => {
          const key = `${index}`
          const { title, cover } = elem
          return <Card title={title} cover={cover} key={key} />
        })}
      </div>
    </section>
  )
}

function Card({ title, cover }: { cover: string; title: string }) {
  return (
    <article className="flex flex-col gap-3 mx-auto  max-w-md lg:max-w-xl translate-y-4 md:opacity-0">
      <div className="grid place-content-center overflow-hidden rounded-xl">
        <img
          src={cover}
          alt={title}
          className="rounded-xl h-full  w-full scale-105 object-cover object-center"
        />
      </div>
      <div className="divider-green h-px w-full mt-3 opacity-70 "></div>
      <h5 className="w-11/12 text-center mx-auto  pt-2  pb-2 font-regular text-white  leading-snug ">
        {title}
      </h5>
    </article>
  )
}
