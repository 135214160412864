import React, { useEffect } from 'react'
import makeInIndia from 'assets/home/certificates/makeInIndia.svg'
import patent from 'assets/home/certificates/patent.svg'
import startupIndia from 'assets/home/certificates/startUpIndia.svg'
import internalTrade from 'assets/home/certificates/internalTrade.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import SplitType from 'split-type'
import SectionHeading from 'molecules/sectionHeading'

gsap.registerPlugin(ScrollTrigger)

export default function Certifications() {
  return (
    <section className=" overflow-hidden relative">
      <div className="blade-top-padding-lg bg-black certificates-wrapper blade-bottom-padding-lg">
        <div className="">
          <SectionHeading
            gradient
            showWheel={false}
            color="light"
            title="Pioneering Quality, Durability and Reliability"
            subtitle="in India’s EV landscape"
          />
        </div>

        <div
          className="blade-top-padding gap-y-10 gap-x-4 xl:gap-x-12  grid lg:grid-cols-4 md:grid-cols-2 grid-cols-1 px-3 
      max-w-screen-xl mx-auto md:w-10/12"
        >
          <PartnerCard desc="Tested and patented products" cover={patent} />
          <PartnerCard
            desc="DPIIT registered <br class='hidden md:block' /> Startup"
            cover={startupIndia}
          />
          <PartnerCard
            desc=">80% of parts are made in India"
            cover={makeInIndia}
          />
          <PartnerCard
            desc="Incubated by ACE foundation of IIT Indore"
            cover={internalTrade}
          />
        </div>
      </div>
    </section>
  )
}
function PartnerCard({
  desc,
  cover,
  imgAlt,
}: {
  imgAlt?: string
  desc: string
  cover: string
}) {
  return (
    <div>
      <article
        style={{
          background:
            'linear-gradient(180deg, #85DB18 0%, rgba(133, 219, 24, 0.35) 100%)',
        }}
        className="relative rounded-lg h-52 group w-10/12 md:w-full mx-auto "
      >
        <div
          className="absolute -inset-[2px] group-hover:inset-[2px] card-child rounded-md transition-all duration-300 ease-in-out  
        grid place-content-center"
        >
          <img src={cover} alt={imgAlt} />
        </div>
      </article>
      <div className="pt-3 lg:pt-8 md:w-[76%] lg:w-[98%] 2xl:w-11/12 mx-auto">
        <h6
          className="text-center text-[#C6C6C6] px-10 lg:px-0 xl:px-5 font-regular"
          dangerouslySetInnerHTML={{ __html: desc }}
        />
      </div>
    </div>
  )
}
