import React, { Fragment, useState } from 'react'
import { z } from 'zod'
import { SubmitHandler, useForm } from 'react-hook-form'
import { zodResolver } from '@hookform/resolvers/zod'

import { MasterBtn } from 'atoms/buttons'
import { toast, ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import emailjs from '@emailjs/browser'

type FormProps = {
  bgImage: any
}

const formSchema = z.object({
  name: z
    .string()
    .min(1, 'Name is required.')
    .max(30, 'Name can not be more than 30 characters.'),
  email: z
    .string()
    .email('Invalid email address.')
    .min(1, 'Email is required.'),
  phone: z.string().length(10, 'Phone number should be of 10 digits.'),
  message: z.string(),
})

export type FormFieldSchema = z.infer<typeof formSchema>

export default function Form({ bgImage }: FormProps) {
  const [isLoading, setLoading] = useState(false)
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm<FormFieldSchema>({
    resolver: zodResolver(formSchema),
  })

  const onSubmit: SubmitHandler<FormFieldSchema> = async (data) => {
    setLoading(true)
    try {
      emailjs
        .send('service_e9pndnq', 'template_15hbcxw', data, '7bXPAlBh81MA01mfx')
        .then(
          function (response) {
            setLoading(false)
            toast.success('Form has been submitted successfully.')
          },
          function (err) {
            setLoading(false)
            toast.error('Error occured. Please try again.')
          },
        )
        .catch(() => {
          toast.error('Failed to submit the form.')
        })
        .finally(() => {
          reset()
        })
    } catch (error) {
      reset()
      toast.error('failed to submit the form.')
    }
  }

  return (
    <>
      <ToastContainer />
      <section
        className="lg:blade-bottom-padding-sm bg-blur-gradient relative lg:blade-top-padding-sm"
        style={{
          background: window.innerWidth >= 1024 ? `url(${bgImage})` : '',
          backgroundRepeat: 'no-repeat',
          backgroundPosition: 'center right 46%',
          backgroundSize: 'cover',
        }}
      >
        <section className="lg:w-container">
          <div className="px-8 xl:px-12 py-6 xl:py-10 lg:rounded-2xl bg-white lg:max-w-[430px] xl:max-w-[500px] max-sm:w-container">
            <p className="text-lightGreen text-center lg:text-left font-semibold text-3xl xl:text-4xl">
              Get in touch
            </p>
            <form
              noValidate
              onSubmit={handleSubmit(onSubmit)}
              className="h-full items-center lg:items-start gap-8 mt-6 md:gap-10 lg:gap-7 flex justify-center flex-col"
            >
              <div className="w-full sm:w-[80%] md:w-[60%] lg:w-full">
                <label
                  htmlFor="name"
                  className="grid opacity-90 text-base xl:text-lg text-black font-medium tracking-wide"
                >
                  <span className="pl-2 font-medium tracking-wider ">
                    {' '}
                    Full Name
                  </span>
                  <input
                    className={` ${
                      errors.name
                        ? 'border-red-500 xl:border-red-700'
                        : 'border-[#dbdbdb] focus:border-green'
                    } px-2 focus:outline-none border-b-2 border-solid bg-transparent text-black
                         text-lg tracking-wide font-medium`}
                    id="name"
                    {...register('name')}
                  />
                  {errors.email && (
                    <span className="text-red-500 lg:text-red-800 font-medium text-sm block mt-1">
                      {errors.name?.message}
                    </span>
                  )}
                </label>
              </div>

              <div className="w-full sm:w-[80%] md:w-[60%] lg:w-full">
                <label
                  htmlFor="email"
                  className="grid opacity-90  text-base xl:text-lg text-black font-medium tracking-wide"
                >
                  <span className="pl-2 font-medium tracking-wider">
                    Email Address
                  </span>
                  <input
                    className={` ${
                      errors.email
                        ? 'border-red-500 lg:border-red-700 '
                        : 'border-[#dbdbdb] focus:border-green'
                    }  px-2 focus:outline-none border-b-2 bg-transparent text-black 
                        font-medium text-lg tracking-wide`}
                    id="email"
                    type="email"
                    inputMode="email"
                    {...register('email')}
                  />
                  {errors.email && (
                    <span className="text-red-500 lg:text-red-800 font-medium text-sm block mt-1">
                      {errors.email?.message}
                    </span>
                  )}
                </label>
              </div>
              <div className="w-full sm:w-[80%] md:w-[60%] lg:w-full">
                <label
                  htmlFor="phone"
                  className="grid opacity-90 text-base xl:text-lg text-black font-medium tracking-wide"
                >
                  <span className="pl-2 font-medium tracking-wider">
                    Contact Number
                  </span>
                  <input
                    className={` ${
                      errors.phone
                        ? 'border-red-500 lg:border-red-700'
                        : 'border-[#dbdbdb] focus:border-green'
                    } px-2 focus:outline-none border-b-2 border-solid bg-transparent   text-black text-lg  tracking-wide font-medium`}
                    type="number"
                    id="phone"
                    inputMode="numeric"
                    {...register('phone')}
                  />
                  {errors.phone && (
                    <span className="text-red-500 lg:text-red-800 font-medium  text-sm block mt-1">
                      {errors.phone?.message}
                    </span>
                  )}
                </label>
              </div>

              <div className="w-full sm:w-[80%] md:w-[60%] lg:w-full">
                <label
                  htmlFor="message"
                  className="grid text-base xl:text-lg text-black font-medium tracking-wide "
                >
                  <span className="pl-2 font-medium tracking-wider">
                    Message
                  </span>
                  <textarea
                    rows={1}
                    cols={5}
                    id="message"
                    className="px-2 border-[#dbdbdb] focus:outline-none focus:border-green
                        border-b-2 border-solid bg-transparent text-black text-lg tracking-wide font-medium"
                    {...register('message')}
                  />
                </label>
              </div>
              <div className="block md:grid place-content-start">
                <MasterBtn
                  type="submit"
                  theme={window.innerWidth >= 1024 ? 'light' : 'dark'}
                  text={isLoading ? 'Loading...' : 'Submit'}
                  isDisabled={isLoading}
                  size="large"
                  className="px-8 py-[10px]"
                />
              </div>
            </form>
          </div>
        </section>
      </section>
      <div className="lg:hidden">
        <img className="w-full h-full" src={bgImage} alt="" />
      </div>
    </>
  )
}
