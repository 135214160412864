import React, { useEffect } from 'react'

import bharat_grayscale from 'assets/home/founders/bharatji.jpg'
import bharat_color from 'assets/home/founders/bharatji_color.jpg'
import manish_grayscale from 'assets/home/founders/manishji.jpg'
import manish_color from 'assets/home/founders/manishji_color.jpg'
import priyanka_grayscale from 'assets/home/founders/priyanka.jpg'
import priyanka_color from 'assets/home/founders/priyanka_color.jpg'
import vinay_grayscale from 'assets/home/founders/vinay.jpg'
import vinay_color from 'assets/home/founders/vinay_color.jpg'
import linkedIn from '../../assets/home/founders/linkedin.svg'

import { gsap } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import SectionHeading from 'molecules/sectionHeading'

gsap.registerPlugin(ScrollTrigger)

type CardProps = {
  reverse: boolean
  imageGrey: any
  imageGradient: any
  title: string
  des: string
  name: string
  designation: string
  profileLink: string
}

const data = [
  {
    id: 1,
    name: ' Bharat Pritmani',
    title: ' The Creative Mind Behind the Wheel',
    des: `With close to three decades of valuable experience of leading global brands for global sales and marketing, product strategy & planning, sales and product management in the automobile domain, an IIM Indore alumni, Bharat has an unrelenting ambition and willingness to walk through walls, ability to trust his ideas, and belief to give back. He propels XERO towards new horizons, constantly pushing the boundaries of what's possible in the areas of his experience.`,
    designation: ' Co-Founder',
    imageGrey: bharat_grayscale,
    imageGradient: bharat_color,
    profileLink:
      'https://www.linkedin.com/in/bharat-pritmani?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
  },
  {
    id: 2,
    name: 'Manish Kachhava',
    title: 'The Master Craftsman of Possibilities',
    des: `With 14 years of remarkable work experience in product design, development, and production with global automobile brands, Manish complements his unique new product design, development, and production lineup and automation competencies. A DGCA certified aircraft maintenance engineer, he possesses the quality of engineering thoughts and restless exhibition. He propels XERO's new product design, development with frugal engineering, and production readiness.`,
    designation: 'Co-Founder',
    imageGrey: manish_grayscale,
    imageGradient: manish_color,
    profileLink:
      'https://www.linkedin.com/in/manish-kachhava-206a6355?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
  },
  {
    id: 3,
    name: 'Vinay Pahuja',
    title: 'The Financial Maestro Fueling Success',
    des: `Vinay boasts almost three decades of experience in steering businesses toward profitability, alongside his triumphant ventures in Central and West India. He supports XERO EV's ambitious objectives, with his vision for the company firmly rooted in sustainability, innovation, and customer value. His strategic decision-making, financial expertise, and knack for forging enduring relationships on all fronts are pivotal to the brand's ascent in the electric vehicle industry. As XERO EV continues to shape the future of sustainable mobility, Vinay's leadership remains integral to the company's ongoing triumphs.`,
    designation: 'Director and CFO',
    imageGrey: vinay_grayscale,
    imageGradient: vinay_color,
    profileLink:
      'https://www.linkedin.com/in/vinay-pahuja-60611b298?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=android_app',
  },
  {
    id: 4,
    name: 'Priyanka Talreja',
    title: 'The Visionary Catalyst',
    des: `Priyanka brings with her 20 years of experience in global marketing, management consulting, and project management. An alumna of IIT Delhi and XLRI Jamshedpur, her commitment to excellence and bias for action propel XERO towards realizing its vision through a customer-centric approach and a focus on sustainable innovation. Priyanka has also led change management projects for the go-to-market team and championed global, business-critical technology projects. With her diverse global experience, she is poised to play a pivotal role in shaping XERO's future.
    `,
    designation: 'Director',
    imageGrey: priyanka_grayscale,
    imageGradient: priyanka_color,
    profileLink:
      'https://www.linkedin.com/in/priyanka-talreja?utm_source=share&utm_campaign=share_via&utm_content=profile&utm_medium=ios_app',
  },
]

const ContentWrapper = ({
  title,
  name,
  designation,
  des,
  profileLink,
}: {
  title: string
  des: string
  designation: string
  name: string
  profileLink: string
}) => {
  return (
    <>
      <div className=" text-white flex flex-col gap-[2px] justify-center lg:pl-10 h-full">
        <div className="flex items-center gap-x-2 md:gap-x-4">
          <h4 className="font-semibold tracking-wider lg:block hidden">
            {name}{' '}
          </h4>
          <h3 className="font-medium tracking-wider lg:hidden block">
            {name}{' '}
          </h3>
          <a
            target="_blank"
            rel="noreferrer"
            href={profileLink}
            className="cursor-pointer z-10"
          >
            <img src={linkedIn} alt="linkedIn logo" />
          </a>
        </div>

        <h6 className="text-green font-medium">{title}</h6>
        <h6 className="font-medium text-xl 2xl:text-2xl text-white text-opacity-60 pt-1 pb-1">
          {designation}
        </h6>
        <span className="font-light md:leading-normal max-w-xl md:w-11/12 text-sm md:text-base 2xl:text-xl">
          {des}
        </span>
      </div>
    </>
  )
}

const Card = ({
  reverse,
  imageGrey,
  imageGradient,
  name,
  title,
  des,
  designation,
  profileLink,
}: CardProps) => {
  if (reverse) {
    return (
      <section className="grid md:grid-cols-2 gap-7 md:gap-10 w-container-lg">
        <ContentWrapper
          title={title}
          des={des}
          designation={designation}
          name={name}
          profileLink={profileLink}
        />
        <div className="relative h-[420px] md:h-96 xl:h-[30rem] 2xl:h-[34rem] group">
          <img
            src={imageGrey}
            alt=""
            className="group-hover:opacity-0 absolute left-0 right-0 bottom-0 top-0 w-full h-full object-cover object-top transition-opacity duration-700 ease-in-out rounded-xl"
          />
          <img
            src={imageGradient}
            alt=""
            className="group-hover:opacity-100 opacity-0 absolute left-0 right-0 bottom-0 top-0 w-full h-full object-cover object-top transition-opacity duration-700 ease-in-out rounded-xl"
          />
        </div>
      </section>
    )
  }
  return (
    <section className="grid grid-cols-1 md:grid-cols-2 gap-7 md:gap-10 w-container-lg">
      <div className="relative rounded-xl h-[420px] md:h-96 xl:h-[30rem] 2xl:h-[34rem] group">
        <img
          src={imageGrey}
          alt=""
          className="group-hover:opacity-0 absolute left-0 right-0 bottom-0 top-0 w-full h-full object-cover object-top transition-opacity duration-700 ease-in-out rounded-xl"
        />
        <img
          src={imageGradient}
          alt=""
          className="group-hover:opacity-100 opacity-0 absolute left-0 right-0 bottom-0 top-0 w-full h-full object-cover object-top transition-opacity duration-700 ease-in-out rounded-xl"
        />
      </div>
      <ContentWrapper
        title={title}
        des={des}
        designation={designation}
        name={name}
        profileLink={profileLink}
      />
    </section>
  )
}

export default function Founders() {
  return (
    <section className="blur-gradient relative overflow-hidden">
      <section className="bg-dark grid-lines blade-top-padding-lg blade-bottom-padding-lg">
        <SectionHeading
          gradient={true}
          color="light"
          title="Architects of XEROvation"
          subtitle="Driving EV Excellence"
        />

        <div className="flex flex-col gap-y-8 md:gap-y-12 lg:gap-y-20 mt-12 md:mt-20 lg:mt-24">
          {data.map((item) => {
            return (
              <>
                <Card
                  title={item.title}
                  name={item.name}
                  des={item.des}
                  designation={item.designation}
                  imageGrey={item.imageGrey}
                  imageGradient={item.imageGradient}
                  profileLink={item.profileLink}
                  reverse={
                    item.id % 2 === 0 && window.innerWidth >= 768 ? true : false
                  }
                />
                {item.id !== data.length && (
                  // <div className="h-[1px] backdrop-blur-sm w-10/12 bg-divider-gradient-green w-container"></div>
                  <div className="w-container flex items-center">
                    <svg
                      xmlns="http://www.w3.org/2000/svg"
                      width="auto"
                      viewBox="0 0 1481 2"
                      className="h-3 md:h-1"
                      fill="none"
                    >
                      <path
                        d="M0.5 0.722656H1480.5"
                        stroke="url(#paint0_linear_2727_2191)"
                      />
                      <defs>
                        <linearGradient
                          id="paint0_linear_2727_2191"
                          x1="26.9877"
                          y1="0.722656"
                          x2="1465.34"
                          y2="0.722656"
                          gradientUnits="userSpaceOnUse"
                        >
                          <stop stop-color="#040404" />
                          <stop stop-color="#040404" />
                          <stop offset="0.489583" stop-color="#E7FFC7" />
                          <stop offset="1" stop-color="#040404" />
                        </linearGradient>
                      </defs>
                    </svg>
                  </div>
                )}
              </>
            )
          })}
        </div>
      </section>
    </section>
  )
}
