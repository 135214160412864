import React from 'react'
import SectionHeading from 'molecules/sectionHeading'
import Image from '../../assets/mink/zero-boundries-img.jpg'

const data = [
  { id: 1, title: 'Safe LFP standard  battery chemistry' },
  { id: 2, title: '50 - 120 km battery  range' },
  {
    id: 3,
    title:
      'Swappable and detachable battery  options with 3-4 hours charging time',
  },
  { id: 4, title: 'Smart BMS for battery protection' },
]

const windowWidth = window.innerWidth

const FeatureDetails = ({ title, id }: { title: string; id: number }) => {
  return (
    <div className="flex gap-x-2 xl:gap-x-3">
      <span className="">
        <svg
          xmlns="http://www.w3.org/2000/svg"
          viewBox="0 0 22 21"
          fill="none"
          className="w-4 h-4 md:w-5 md:h-5 xl:w-6 xl:h-6 2xl:mt-[4px]"
        >
          <path
            d="M11.1133 0.734375L13.5847 8.34059L21.5823 8.34059L15.1121 13.0415L17.5835 20.6477L11.1133 15.9468L4.64305 20.6477L7.11446 13.0415L0.64423 8.34059L8.64187 8.34059L11.1133 0.734375Z"
            fill="#D9D9D9"
          />
          <path
            d="M11.1133 0.734375L13.5847 8.34059L21.5823 8.34059L15.1121 13.0415L17.5835 20.6477L11.1133 15.9468L4.64305 20.6477L7.11446 13.0415L0.64423 8.34059L8.64187 8.34059L11.1133 0.734375Z"
            fill="url(#paint0_linear_2727_1692)"
          />
          <defs>
            <linearGradient
              id="paint0_linear_2727_1692"
              x1="11.1133"
              y1="-11.3358"
              x2="11.1133"
              y2="31.2128"
              gradientUnits="userSpaceOnUse"
            >
              <stop stop-color="white" />
              <stop offset="0.861167" stop-color="#85DB18" />
            </linearGradient>
          </defs>
        </svg>
      </span>
      <p
        dangerouslySetInnerHTML={{ __html: title }}
        className={`font-regular text-white text-base xl:text-xl 2xl:text-2xl  ${
          id === 3
            ? `max-w-[320px] ${
                1170 > windowWidth && windowWidth >= 1024
                  ? 'max-w-[180px]'
                  : 'lg:max-w-[240px] xl:max-w-[260px] 2xl:max-w-[420px]'
              }`
            : `max-w-[300px] ${
                1170 > windowWidth && windowWidth >= 1024
                  ? 'max-w-[160px]'
                  : 'lg:max-w-[160px] xl:max-w-[180px] 2xl:max-w-[280px]'
              }`
        }`}
      />
    </div>
  )
}

export default function ZeroBoundries() {
  return (
    <div className="blur-gradient relative overflow-hidden">
      <div className="blade-top-padding-lg blade-bottom-padding-lg bg-black">
        <div className="w-container-xl">
          <SectionHeading title="Zero Boundaries" gradient />
          <div className="w-full lg:max-w-[80%] 2xl:max-w-full mx-auto sm:h-auto lg:h-[400px] 2xl:h-auto mt-8 md:mt-12 2xl:mt-16">
            <img
              className="w-full h-full rounded-xl md:rounded-3xl object-cover"
              src={Image}
              alt=""
            />
          </div>

          <div className="flex justify-center mt-8 md:mt-12">
            <div className="flex flex-col flex-wrap xl:flex-nowrap lg:flex-row gap-y-4 2xl:px-2 lg:justify-center gap-x-4 xl:gap-x-8">
              {data.map((item) => (
                <>
                  <FeatureDetails
                    id={item.id}
                    key={item.id}
                    title={item.title}
                  />
                  <div
                    className={`mx-auto w-[200px] h-[1px] lg:w-[1px] lg:h-[80px] bg-[#7D7D7D] ${
                      item.id === data.length ? 'hidden' : 'hidden lg:block'
                    }`}
                  />
                </>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}
