import React, { useRef, useEffect, ReactNode } from 'react'
import ScrollReveal from 'scrollreveal'

export default function Reveal({
  children,
  delay = 400,
  duration = 700,
  className = '',
}: {
  duration?: number
  className?: string
  delay?: number
  children: ReactNode
}) {
  const textRef = useRef<HTMLDivElement>(null)
  useEffect(() => {
    if (textRef) {
      ScrollReveal().reveal(textRef.current as any, {
        duration,
        delay,
      })
    }
  }, [])
  return (
    <div ref={textRef} className={className === '' ? '' : className}>
      {children}
    </div>
  )
}
