import React, { useLayoutEffect } from 'react'
import logo from 'assets/coming_soon/logo.svg'
import { MasterBtn } from 'atoms/buttons'
import { useNavigate } from 'react-router-dom'
import Header from 'organisms/header'
import Footer from 'organisms/footer'

export default function ComingSoon() {
  const navigate = useNavigate()
  const returnToHomepage = () => navigate('/')

  useLayoutEffect(() => {
    window.scrollTo({ top: 0 })
  }, [])

  return (
    <>
      <section
        style={{
          background:
            'radial-gradient(circle at center center ,  #3d3d3d , black 85% )',
        }}
        className=" bg-black h-screen grid place-content-center gap-1"
      >
        <div className="grid place-content-center w-7/12 mx-auto">
          <img
            src={logo}
            alt="Coming Soon"
            className=" h-20 xl:h-32 w-auto object-contain object-center"
          />
        </div>
        <div>
          <h1 className=" text-center gradient-lightGreen-green text-transparent bg-clip-text font-medium text-5xl lg:text-7xl">
            Coming Soon
          </h1>
        </div>
        <div className="grid place-content-center place-items-center pt-5 md:pt-10">
          <MasterBtn
            onClick={returnToHomepage}
            text="Return to Home"
            size="xxl"
            type="button"
          />
        </div>
      </section>
    </>
  )
}
