/* eslint-disable jsx-a11y/no-noninteractive-element-interactions */
import React, {
  useReducer,
  useRef,
  useEffect,
  MouseEventHandler,
  useState,
  useMemo,
} from 'react'
import xero from 'assets/globals/xero_logo.svg'

import { gsap, Power1 } from 'gsap'
import { ScrollTrigger } from 'gsap/dist/ScrollTrigger'
import { Anchor, MasterAnchor, TextAnchor, TextNavAnchor } from 'atoms/links'
import { NavLink, useNavigate, useLocation } from 'react-router-dom'
import { Popover, Transition as PopTransition } from '@headlessui/react'

gsap.registerPlugin(ScrollTrigger)

export default function Header() {
  const [isMobileNavActive, toggleNav] = useReducer((s) => !s, false)
  const navigate = useNavigate()
  const location = useLocation()
  const [isNavFixed, setNavFixed] = useState(true)

  const headerWrapper = useRef<HTMLElement>(null)
  const bSeriesbtn = useRef<HTMLButtonElement>(null)
  const mSeriesBtn = useRef<HTMLButtonElement>(null)

  useEffect(() => {
    if (isMobileNavActive) document.documentElement.style.overflow = 'hidden'
    else document.documentElement.style.overflow = 'auto'
  }, [isMobileNavActive])

  const currentpage = useMemo(
    () => location.pathname.substring(1).split('-')[0],
    [location],
  )

  useEffect(() => {
    window.addEventListener('scroll', () => {
      const scrollPos = window.scrollY
      if (scrollPos > 100) {
        setNavFixed(false)
      } else {
        setNavFixed(true)
      }
    })

    if (window.innerWidth >= 1024) {
      const showAnim = gsap
        .from(headerWrapper.current, {
          yPercent: -100,
          paused: true,
          duration: 0.2,
        })
        .progress(1)

      ScrollTrigger.create({
        start: 'top top',
        end: 99999,
        onUpdate: (self) => {
          self.direction === -1 ? showAnim.play() : showAnim.reverse()
        },
      })
    }
  }, [])

  const popNavigation = (target: string, popId: 'bseries' | 'mseries') => {
    switch (popId) {
      case 'bseries': {
        navigate(target)
        return bSeriesbtn.current?.click()
      }
      case 'mseries': {
        navigate(target)
        return mSeriesBtn.current?.click()
      }
      default:
    }
  }

  return (
    <header
      ref={headerWrapper}
      className={`${
        isNavFixed ? 'bg-transparent' : 'bg-white'
      } lg:h-[84px] py-4 fixed top-0 left-0 right-0 !z-50 `}
    >
      <div
        className="max-w-screen-2xl justify-between mx-auto md:w-[96%] xl:w-11/12 2xl:w-11/12 px-3 items-center top-0 left-0 right-0 
      bottom-auto z-50 flex"
      >
        <div className="">
          <Anchor href="/" target="_self">
            <img src={xero} className="md:h-12 h-9" alt="Xero logo" />
          </Anchor>
        </div>

        <div className=" hidden lg:flex items-center gap-4 2xl:gap-12">
          {window.innerWidth > 1170 && (
            <TextNavAnchor className="" href="/" text="Home" />
          )}
          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button
                  onClick={() => {
                    window.addEventListener('scroll', () => close(), {
                      once: true,
                    })
                  }}
                  ref={bSeriesbtn}
                  className={`font-medium flex flex-nowrap items-center gap-1 outline-none
                  tracking-wide  text-sm  md:text-base 2xl:text-lg hover:text-darkGreen transition-colors 
                  duration-100 ease-in-out leading-tight px-2 ${
                    open ? 'text-darkGreen text-opacity-100' : 'text-black'
                  }`}
                >
                  B Series
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                    className={` ${
                      open ? 'rotate-180' : ''
                    } transition-all duration-300 ease-in-out w-4 h-4`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </Popover.Button>

                <PopTransition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute top-20 md:top-20"
                >
                  <Popover.Panel className="md:w-[16rem] rounded-md  grid grid-cols-1 gap-x-6 bg-white">
                    <div className="px-2 font-medium py-1 grid divide-y-1 divide-slate-200 gap-1">
                      <PopOption
                        callback={() => popNavigation('bolt', 'bseries')}
                        text="Bolt"
                      />
                    </div>
                  </Popover.Panel>
                </PopTransition>
              </>
            )}
          </Popover>

          <Popover>
            {({ open, close }) => (
              <>
                <Popover.Button
                  onClick={() => {
                    window.addEventListener('scroll', () => close(), {
                      once: true,
                    })
                  }}
                  ref={mSeriesBtn}
                  className={`font-medium flex flex-nowrap items-center gap-1 outline-none
                  tracking-wide  text-sm  md:text-base 2xl:text-lg hover:text-darkGreen transition-colors 
                  duration-100 ease-in-out leading-tight px-2 ${
                    open ? 'text-darkGreen text-opacity-100' : 'text-black'
                  }`}
                >
                  M Series
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    fill="none"
                    viewBox="0 0 24 24"
                    strokeWidth="3"
                    stroke="currentColor"
                    className={` ${
                      open ? 'rotate-180' : ''
                    } transition-all duration-300 ease-in-out w-4 h-4`}
                  >
                    <path
                      strokeLinecap="round"
                      strokeLinejoin="round"
                      d="M19.5 8.25l-7.5 7.5-7.5-7.5"
                    />
                  </svg>
                </Popover.Button>

                <PopTransition
                  show={open}
                  enter="transition duration-100 ease-out"
                  enterFrom="transform scale-95 opacity-0"
                  enterTo="transform scale-100 opacity-100"
                  leave="transition duration-75 ease-out"
                  leaveFrom="transform scale-100 opacity-100"
                  leaveTo="transform scale-95 opacity-0"
                  className="absolute top-20 md:top-20"
                >
                  <Popover.Panel className="md:w-[16rem] rounded-md  grid grid-cols-1 gap-x-6 bg-white">
                    <div className="px-2 font-medium py-1 grid divide-y-1 divide-slate-200 gap-1">
                      <PopOption
                        callback={() => popNavigation('mink', 'mseries')}
                        text="Mink"
                      />
                    </div>
                  </Popover.Panel>
                </PopTransition>
              </>
            )}
          </Popover>
          <TextNavAnchor href="/#about-us" text="About Us" />
          <TextNavAnchor
            href={
              currentpage === 'cookie' ||
              currentpage === 'privacy' ||
              currentpage === 'disclaimer'
                ? '/#contact-us'
                : '#contact-us'
            }
            text="Contact Us"
          />
        </div>
        <div className="lg:grid grid-cols-2 xl:grid-cols-2 hidden gap-x-4">
          <MasterAnchor
            className="px-4 xl:px-6 bg-lightGreen border-none !py-3 md:!py-[10px]"
            href={
              currentpage === 'cookie' ||
              currentpage === 'privacy' ||
              currentpage === 'disclaimer'
                ? '/#partner-with-us'
                : '#partner-with-us'
            }
            text="Partner with us"
          />
          <MasterAnchor
            className="px-0 xl:px-6 bg-lightGreen border-none !py-3 md:!py-[10px]"
            href={
              currentpage === 'cookie' ||
              currentpage === 'privacy' ||
              currentpage === 'disclaimer'
                ? '/#book-now'
                : '#book-now'
            }
            text="Book now"
          />
        </div>

        <button className="lg:hidden block" onClick={toggleNav}>
          <svg
            stroke="currentColor"
            fill="currentColor"
            stroke-width="0"
            viewBox="0 0 20 20"
            aria-hidden="true"
            className="h-6"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M3 5a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1zM3 10a1 1 0 011-1h6a1 1 0 110 2H4a1 1 0 01-1-1zM3 15a1 1 0 011-1h12a1 1 0 110 2H4a1 1 0 01-1-1z"
            ></path>
          </svg>
        </button>
      </div>

      <div
        className={` ${
          isMobileNavActive
            ? 'translate-x-0 border-opacity-30'
            : 'translate-x-full'
        }   fixed top-0 left-0 pt-4 right-0 bottom-0 overflow-hidden tranistion-all duration-700 ease-in-out bg-white flex flex-col z-[9999999]`}
      >
        <div className="w-container-sm w-full h-full pb-10">
          <div className="flex w-full  justify-between">
            <Anchor href="/" target="_self" onNavigate={toggleNav}>
              <img src={xero} className=" md:h-12 h-9" alt="Xero logo" />
            </Anchor>
            <button onClick={toggleNav}>
              <svg
                stroke="currentColor"
                fill="currentColor"
                stroke-width="0"
                viewBox="0 0 512 512"
                className="h-7"
                xmlns="http://www.w3.org/2000/svg"
              >
                <path d="M289.94 256l95-95A24 24 0 00351 127l-95 95-95-95a24 24 0 00-34 34l95 95-95 95a24 24 0 1034 34l95-95 95 95a24 24 0 0034-34z"></path>
              </svg>
            </button>
          </div>

          <div className="flex pt-4 flex-col justify-between mx-auto w-full h-full pb-5">
            <div className="flex flex-col gap-3  justify-between py-5">
              <TextNavAnchor
                size="xxl"
                text="Home"
                href="/"
                onNavigate={() => toggleNav()}
              />
              <TextNavAnchor
                size="xxl"
                text="Bolt"
                href="/bolt"
                onNavigate={() => toggleNav()}
              />
              <TextNavAnchor
                size="xxl"
                text="Mink"
                href="/mink"
                onNavigate={() => toggleNav()}
              />
              <TextNavAnchor
                size="xxl"
                text="About"
                href="/#about-us"
                onNavigate={() => toggleNav()}
              />
              <TextNavAnchor
                size="xxl"
                text="Contact"
                href={
                  currentpage === 'cookie' ||
                  currentpage === 'privacy' ||
                  currentpage === 'disclaimer'
                    ? '/#contact-us'
                    : '#contact-us'
                }
                onNavigate={() => toggleNav()}
              />
            </div>
            <div className="flex flex-col gap-y-4 justify-center">
              <MasterAnchor
                className="px-4 xl:px-6 bg-lightGreen border-none"
                href={
                  currentpage === 'cookie' ||
                  currentpage === 'privacy' ||
                  currentpage === 'disclaimer'
                    ? '/#partner-with-us'
                    : '#partner-with-us'
                }
                text="Partner with us"
                onNavigate={() => toggleNav()}
              />
              <MasterAnchor
                href={
                  currentpage === 'cookie' ||
                  currentpage === 'privacy' ||
                  currentpage === 'disclaimer'
                    ? '/#book-now'
                    : '#book-now'
                }
                className="bg-lightGreen border-none"
                text="Book now"
                onNavigate={() => toggleNav()}
              />
            </div>
          </div>
        </div>
      </div>
    </header>
  )
}

function PopOption({
  callback,
  text,
}: {
  text: string
  callback: MouseEventHandler<HTMLButtonElement>
}) {
  return (
    <button
      type="button"
      onClick={callback}
      className=" py-4 px-3 group flex flex-nowrap items-center gap-1 group stroke-violet fill-violet font-medium  
      outline-none focus:outline-none"
    >
      <span className="font-medium group-hover:text-darkGreen text-black">
        {text}
      </span>
    </button>
  )
}
